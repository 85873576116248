import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { Button, Heading } from '@kiiwi/ui';

import { useTranslation } from '../../../i18n';
import Typography from '../../atoms/Typography';

import { APP_DOWNLOAD_LINK, CONNECT_DOWNLOAD_LINK } from '@/src/constants';
import Routes from '@/src/constants/routes';

const MobileLandingPage = () => {
  const { t } = useTranslation(['common', 'connect']);
  const router = useRouter();

  useEffect(() => {
    document.body.classList.add('mobile-login-page');
  }, []);

  return (
    <div>
      <div className="min-h-[190px]  bg-[url(/static/images/vector-gradient-connect.svg)] bg-cover bg-left-bottom bg-no-repeat px-6 pt-10">
        <img
          src="/static/images/brand-logo.svg"
          alt={t('login_page_mobile_title')}
        />
        <Heading as="h2" variant="title-3" className="mt-4 max-w-96 text-white">
          {t('login_page_mobile_title')}
        </Heading>
      </div>

      <div className="px-13 py-8 text-center">
        <Heading as="h2" variant="title-1" className="mb-2 text-[#444D56]">
          {t('connect:page_maiia_connect')}
        </Heading>
        <Heading
          as="h2"
          variant="title-3"
          className="mx-auto max-w-64 font-semibold text-[#444D56]"
        >
          {t('connect:login_page_mobile_connect_subtitle')}
        </Heading>
        <img
          className="mx-auto mt-6 block"
          src="/static/images/praticiens.svg"
          alt={t('connect:login_page_mobile_connect_subtitle')}
        />
      </div>

      <div className="mx-auto mb-8 max-w-96">
        <Typography
          variant="body-1"
          className="px-6 text-center font-semibold text-[#444D56]"
        >
          {t('connect:login_page_mobile_connect_text')}
        </Typography>
        <Button
          color="macro"
          variant="primary"
          className="mx-auto mt-3 block px-12"
          onClick={() => router.push(CONNECT_DOWNLOAD_LINK)}
        >
          {t('connect:login_page_mobile_connect_download_btn')}
        </Button>
        <Button
          variant="secondary"
          className="mx-auto mt-3 block "
          onClick={() => router.push(Routes.SIGNUP)}
        >
          {t('connect:login_page_mobile_connect_signup')}
        </Button>
      </div>
      <div className="min-h-[216px] bg-[url(/static/images/vector-gradient-pro.svg)] bg-cover bg-left-top bg-no-repeat pb-2 pt-5">
        <div className="px-13 py-6 text-center">
          <Heading as="h2" variant="title-1" className="mb-2 text-[#444D56]">
            {t('maiia_pro')}
          </Heading>
          <Heading
            as="h2"
            variant="title-3"
            className="mx-auto max-w-96 font-semibold text-[#444D56]"
          >
            {t('login_page_mobile_pro_title')}
          </Heading>
          <img
            className="mx-auto mt-6 block"
            src="/static/images/agenda-mobile.svg"
            alt={t('login_page_mobile_pro_title')}
          />
        </div>
        <div className="mx-auto mb-8 max-w-96">
          <Typography
            variant="body-1"
            className="px-6 text-center font-semibold text-[#444D56]"
          >
            {t('login_page_mobile_pro_text')}
          </Typography>
          <Button
            color="macro"
            variant="primary"
            className="mx-auto mt-3 block"
            onClick={() => router.push(APP_DOWNLOAD_LINK)}
          >
            {t('login_page_mobile_pro_btn')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MobileLandingPage;
