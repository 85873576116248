import React from 'react';
import { cn } from '@kiiwi/ui';

export enum DigitInputStatus {
  EMPTY = 'EMPTY',
  TYPING = 'TYPING',
  ERROR = 'ERROR',
}

const DigitInput = React.forwardRef<
  HTMLInputElement,
  { status?: DigitInputStatus } & React.HTMLProps<HTMLInputElement>
>(({ status = DigitInputStatus.EMPTY, ...props }, ref) => {
  const isTyping = status === DigitInputStatus.TYPING;
  const isError = status === DigitInputStatus.ERROR;

  return (
    <div className="flex flex-col gap-[var(--kiiwi-spacing-12)]">
      <input
        ref={ref}
        className={cn([
          'w-[57px] rounded-lg border-[1px] border-solid border-transparent px-4 py-2 text-center text-[40px]',
          'focus:border-brand-selected outline-none',
          'bg-surface-light',
          isTyping && 'bg-success-alert',
          isError && 'bg-danger-alert',
        ])}
        required
        maxLength={1}
        size={1}
        pattern="\d*"
        autoComplete="off"
        inputMode="numeric"
        {...props}
      />
      <div
        className={cn([
          'h-[3px] rounded-lg',
          'bg-[var(--kiiwi-border-neutral)]',
          isTyping && 'bg-[var(--kiiwi-border-alert-success)]',
          isError && 'bg-[var(--kiiwi-border-alert-error)]',
        ])}
      />
    </div>
  );
});

DigitInput.displayName = 'DigitInput';

export default DigitInput;
