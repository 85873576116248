import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { FormikProps } from 'formik';
import { makeStyles } from 'tss-react/mui';

import { asyncActions } from '@docavenue/core';

import { useTranslation } from '../../../../../../i18n';
import {
  GAAuthenticationActions,
  GACategories,
} from '../../../../../../src/constants/tracking';
import { useAnalyticsTracker } from '../../../../../../src/hooks/utils';
import { TextField, TextFieldController } from '../../../../../atoms/Fields';
import FontAwesomeIcon from '../../../../../atoms/FontawesomeIcon/FontawesomeIcon';
import InputAdornment from '../../../../../atoms/InputAdornment';
import Typography from '../../../../../atoms/Typography';
import FieldsBuilder, {
  FieldType,
} from '../../../../../organisms/FieldsBuilder';

import LoginPasswordSchema from '@/components/FormikSchema/LoginPasswordSchema';
import FormikBuilder from '@/components/organisms/FormikBuilder';
import { authenticationActions } from '@/src/actions';
import { useLoginAction } from '@/src/hooks/actions';

const useStyles = makeStyles()((theme: any) => ({
  loginFormPasswordView: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5),
    '& .hidden': {
      display: 'none',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  forgotPasswordButton: {
    alignSelf: 'flex-end',
    fontStyle: 'italic',
  },
}));

type LoginFormPasswordInitialValue = {
  username: string;
  password: string;
};
type Props = {
  autoLogin: {
    tokenHash?: string;
    hash?: string;
    data?: string;
    tsToken?: string;
  };
  username: string;
  goToLogin: () => void;
  goToOneTimePIN: (mfaToken: string) => void;
  goToForgotPassword: () => void;
};

const LoginFormPasswordStepView = ({
  autoLogin,
  username,
  goToLogin,
  goToOneTimePIN,
  goToForgotPassword,
}: Props) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const loginAction = useLoginAction();
  const triggerAnalyticsEvent = useAnalyticsTracker(
    GACategories.Authentication,
  );
  const triggerAnalyticsEventRef = useRef(triggerAnalyticsEvent);
  triggerAnalyticsEventRef.current = triggerAnalyticsEvent;
  const authenticationError = useSelector(
    state => state.authentication.error,
  ) as any;
  const { status, code } = authenticationError || {};
  const subCode = authenticationError?.subCodes?.[0];
  const mfaToken =
    authenticationError &&
    'mfaToken' in authenticationError &&
    typeof authenticationError.mfaToken === 'string'
      ? authenticationError.mfaToken
      : undefined;
  const formRef = useRef<FormikProps<LoginFormPasswordInitialValue>>(null);
  const fields: Array<FieldType> = [
    {
      name: 'username',
      component: TextFieldController,
      fieldProps: {
        className: 'hidden',
        InputComponent: TextField,
        inputProps: {
          id: 'username',
          name: 'username',
        },
      },
    },
    {
      name: 'password',
      component: TextFieldController,
      fieldProps: {
        autoComplete: 'password',
        autoFocus: true,
        InputComponent: TextField,
        placeholder: t('auth_card__password_placeholder'),
        type: showPassword ? 'text' : 'password',
        inputProps: {
          id: 'password',
          name: 'password',
          onFocus: () => dispatch(authenticationActions.resetError()),
        },
        InputProps: {
          endAdornment: (
            <InputAdornment
              position="end"
              onClick={() => setShowPassword(!showPassword)}
              className="mr-2"
            >
              {showPassword ? (
                <FontAwesomeIcon
                  name="eye:regular"
                  className="text-neutral-primary h-5 w-5"
                />
              ) : (
                <FontAwesomeIcon
                  name="eye-slash:regular"
                  className="text-neutral-primary h-5 w-5"
                />
              )}
            </InputAdornment>
          ),
          startAdornment: (
            <InputAdornment position="end">
              <FontAwesomeIcon
                name="lock-keyhole:regular"
                className="text-neutral-primary h-5 w-5"
              />
            </InputAdornment>
          ),
        },
      },
    },
  ];

  const handleSubmit = async (
    values: LoginFormPasswordInitialValue,
  ): Promise<void> => {
    try {
      await asyncActions(
        dispatch,
        loginAction({
          ...autoLogin,
          username,
          password: values.password,
        }),
      );
    } catch (e) {
      // empty
    }
  };

  const formikProps = {
    formRef,
    initialValues: { username: '', password: '' },
    onSubmit: handleSubmit,
    validationSchema: LoginPasswordSchema,
    validateOnBlur: false,
    validateOnChange: false,
  };

  useEffect(() => {
    if (
      (status === 401 && code === 'MFA_EXPIRED') ||
      (status === 404 && code === 'MFA_NOT_FOUND')
    ) {
      dispatch(authenticationActions.resetError());
      goToLogin();
    } else if (status === 461 && code === 'MFA_NEEDED') {
      if (mfaToken) {
        dispatch(authenticationActions.resetError());
        goToOneTimePIN(mfaToken);
        triggerAnalyticsEventRef.current(
          GAAuthenticationActions.StartMultiFactorAuthentication,
        );
      }
    }
  }, [code, dispatch, mfaToken, status]);

  return (
    <FormikBuilder {...formikProps}>
      {({
        isSubmitting,
        values,
      }: FormikProps<LoginFormPasswordInitialValue>) => (
        <>
          <div>
            <Typography component="h2">
              {t('login_page__hello', { username: values.username })}
            </Typography>
            <Typography>{t('login_page__hellophrase')}</Typography>
          </div>
          <FieldsBuilder
            fields={fields}
            className={classes.loginFormPasswordView}
          >
            {status === 401 && code === 'AUTH_ERROR_001' && subCode && (
              <Typography
                className="errorMessage"
                gridkey="message"
                datacy="error"
              >
                {t('login_error')}
              </Typography>
            )}
            {status === 429 && code === 'MFA_TOO_MANY_REQUESTS' && (
              <Typography
                className="errorMessage"
                gridkey="message"
                datacy="error"
              >
                {t('too_many_mfa_token_creations')}
              </Typography>
            )}
            {status === 401 &&
              (code === 'USER_TEMPORARILY_BLOCKED' ||
                code === 'USER_BLOCKED') && (
                <Typography
                  className="errorMessage"
                  gridkey="message"
                  datacy="error"
                >
                  {t('login_error_blocked_account')}
                </Typography>
              )}
            {status === 401 && code === 'SYNC_ERROR_AUTOLOGIN_000' && (
              <Typography
                className="errorMessage"
                gridkey="message"
                datacy="error"
              >
                {t('autologin_error')}
              </Typography>
            )}
            <div className={classes.buttonContainer}>
              <Button
                variant="text"
                disabled={isSubmitting}
                onClick={goToLogin}
                startIcon={
                  <FontAwesomeIcon
                    name="angle-left:regular"
                    className="h-3 w-3"
                  />
                }
              >
                <span>{t('back_action_label')}</span>
              </Button>
              <Button
                datacy="submit_btn"
                variant="contained"
                color="primary"
                type="submit"
                disabled={!values.password || isSubmitting}
              >
                {t('auth_card__button_login')}
              </Button>
            </div>
            <Button
              className={classes.forgotPasswordButton}
              variant="text"
              color="text"
              datacy="forgot_password_btn"
              disabled={isSubmitting}
              onClick={goToForgotPassword}
            >
              {t('auth_card__forgot_password')}
            </Button>
          </FieldsBuilder>
        </>
      )}
    </FormikBuilder>
  );
};

export default LoginFormPasswordStepView;
