import React, { useRef } from 'react';
import { Button } from '@mui/material';
import { useKeycloak } from '@react-keycloak/ssr';
import { FormikProps } from 'formik';
import { KeycloakInstance } from 'keycloak-js';
import { makeStyles } from 'tss-react/mui';

import { useTranslation } from '../../../../../../i18n';
import {
  useIsAccountAlreadyUnlocked,
  useIsSessionExpired,
} from '../../../../../../src/hooks/query';
import { TextField, TextFieldController } from '../../../../../atoms/Fields';
import FontAwesomeIcon from '../../../../../atoms/FontawesomeIcon/FontawesomeIcon';
import InputAdornment from '../../../../../atoms/InputAdornment';
import Typography from '../../../../../atoms/Typography';
import FieldsBuilder, {
  FieldType,
} from '../../../../../organisms/FieldsBuilder';

import LoginUsernameSchema from '@/components/FormikSchema/LoginUsernameSchema';
import FormikBuilder from '@/components/organisms/FormikBuilder';
import { useLoginAction } from '@/src/hooks/actions';

const useStyles = makeStyles()((theme: any) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(5),
  },
  accountAlreadyUnlocked: {
    paddingBottom: theme.spacing(3),
  },
}));

enum USER_LOGIN_TYPES {
  UAA = 'UAA',
  AGENDA = 'AGENDA',
}

const getUserLoginType = async (
  username: string,
): Promise<USER_LOGIN_TYPES | undefined> => {
  let response: Response;
  try {
    response = await fetch(`api/auth/uaa/${username}`);
  } catch (error) {
    console.warn(error);
    return;
  }
  if (response.status === 200) {
    const userLoginType = await response.text();
    if (
      Object.values(USER_LOGIN_TYPES).includes(
        userLoginType as USER_LOGIN_TYPES,
      )
    ) {
      return userLoginType as USER_LOGIN_TYPES;
    }
  }
};

type LoginFormUsernameInitialValue = {
  username: string;
};

type Props = {
  goToPassword: (username: string) => void;
  goToSignUp: () => void;
};

const LoginFormUsernameStepView = ({ goToPassword, goToSignUp }: Props) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const loginAction = useLoginAction();
  const loginActionRef = useRef(loginAction);
  loginActionRef.current = loginAction;
  const isSessionExpired = useIsSessionExpired();
  const isAccountAlreadyUnlocked = useIsAccountAlreadyUnlocked();
  const { keycloak } = useKeycloak<KeycloakInstance>();
  const formRef = useRef<FormikProps<LoginFormUsernameInitialValue>>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const fields: Array<FieldType> = [
    {
      name: 'username',
      component: TextFieldController,
      fieldProps: {
        autoComplete: 'username',
        autoFocus: true,
        InputComponent: TextField,
        placeholder: t('login_page__username_placeholder'),
        type: 'email',
        inputProps: {
          ref: inputRef,
          id: 'username',
          name: 'username',
        },
        InputProps: {
          startAdornment: (
            <InputAdornment position="end">
              <FontAwesomeIcon
                name="user:regular"
                className="text-neutral-primary h-5 w-5"
              />
            </InputAdornment>
          ),
        },
      },
    },
  ];

  const handleSubmit = async (
    values: LoginFormUsernameInitialValue,
  ): Promise<void> => {
    const userLoginType = await getUserLoginType(values.username);
    if (userLoginType === USER_LOGIN_TYPES.UAA) {
      // SSO is enabled for this user so redirect to SSO login page.
      keycloak?.login({ loginHint: values.username });
    } else if (userLoginType === USER_LOGIN_TYPES.AGENDA) {
      goToPassword(values.username);
    }
  };

  const formikProps = {
    formRef,
    initialValues: { username: '' },
    onSubmit: handleSubmit,
    validationSchema: LoginUsernameSchema,
    validateOnBlur: false,
    validateOnChange: false,
  };

  return (
    <FormikBuilder {...formikProps}>
      {() => (
        <>
          <div>
            <Typography component="h2">{t('login_page__new_login')}</Typography>
            <Typography>
              {t(
                isSessionExpired
                  ? 'session_expired'
                  : 'login_page__catchphrase',
              )}
            </Typography>
          </div>
          <FieldsBuilder fields={fields} className={classes.form}>
            {isAccountAlreadyUnlocked && (
              <Typography
                variant="bodySmall"
                color="green.500"
                className={classes.accountAlreadyUnlocked}
              >
                {t('login_page__account_already_unlocked')}
              </Typography>
            )}
            <Button
              datacy="submit_btn"
              variant="contained"
              color="primary"
              type="submit"
            >
              {t('login_page__next_button')}
            </Button>
          </FieldsBuilder>
          <div className="wannajoin createAccount">
            <Typography>{t('login_page__joinus')}</Typography>
            <Typography className="bold">
              {t('login_page__for_free')}
            </Typography>
            <div>
              <Button
                className="btn"
                onClick={goToSignUp}
                sx={{
                  '&:hover': {
                    backgroundColor: '#E0F5F4',
                  },
                }}
              >
                {t('login_page__new_account')}
              </Button>{' '}
              <div className="heart" />
            </div>
          </div>
        </>
      )}
    </FormikBuilder>
  );
};

export default LoginFormUsernameStepView;
