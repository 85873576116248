import React, { useEffect, useState } from 'react';
import { cn, Typography } from '@kiiwi/ui';
import dayjs from 'dayjs';

import { useTranslation } from '../../../../../../i18n';

const COUNTDOWN_DURATION = 60;

type ResendOneTimePINProps = {
  oneTimePINCreationDate: string;
  hasReachedLimit?: boolean;
  onClick?: React.MouseEventHandler<HTMLSpanElement>;
};

export const ResendOneTimePIN = ({
  oneTimePINCreationDate,
  hasReachedLimit = false,
  onClick,
}: ResendOneTimePINProps) => {
  const { t } = useTranslation();
  const [countdown, setCountdown] = useState(0);

  const formatDuration = (duration: number): string => {
    const minutes = Math.floor(duration / 60);
    const seconds = duration % 60;
    return [
      minutes.toString().padStart(2, '0'),
      seconds.toString().padStart(2, '0'),
    ].join(':');
  };

  const resendOneTimePIN: React.MouseEventHandler<HTMLSpanElement> = event => {
    if (countdown === 0 && !hasReachedLimit) {
      onClick?.(event);
    }
  };

  useEffect(() => {
    const initialCountdown = dayjs(oneTimePINCreationDate)
      .add(COUNTDOWN_DURATION, 'second')
      .diff(dayjs(), 'second');
    if (initialCountdown > 0) {
      setCountdown(initialCountdown);
    }
    const intervalId = setInterval(() => {
      setCountdown(prevCountdown => {
        if (prevCountdown > 0) {
          return prevCountdown - 1;
        }
        clearInterval(intervalId);
        return 0;
      });
    }, 1000);
    return () => clearInterval(intervalId);
  }, [oneTimePINCreationDate]);

  return (
    <>
      <Typography className="text-center">
        {`${t('login_page__new_login_second_factor_no_received_code')} `}
        <span
          onClick={resendOneTimePIN}
          className={cn(
            'font-bold',
            countdown > 0 || hasReachedLimit
              ? 'text-neutral-disabled'
              : 'text-brand-secondary-enabled cursor-pointer',
          )}
        >
          {t('login_page__new_login_second_factor_resend_code')}
          {countdown > 0 && ` (${formatDuration(countdown)})`}
        </span>
      </Typography>
      {hasReachedLimit && (
        <Typography className="text-error">
          {t('login_page__new_login_second_factor_resend_code_error')}
        </Typography>
      )}
    </>
  );
};
